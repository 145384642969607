import { setFormat, setId, updateCurrentConversation } from "./currentConversationBriefSlice";
import DB from "../db";
import { setToken } from "./authSlice";

export const localStorageMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type === setId.type) {
    DB.setCurrentConversationId(action.payload);
  }
  if (action.type === updateCurrentConversation.type) {
    console.log(`updateCurrentConversation ${action.payload.id} ${action.payload.format}`);
    DB.setCurrentConversationId(action.payload.id);
  }
  if (action.type === setToken.type) {
    DB.setToken(action.payload);
  }

  return result;
};
