import { useEffect } from 'react';

const useAdaptiveTheme = () => {
  useEffect(() => {
    const updateDarkMode = (isDarkMode) => {
      if (isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    };

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    updateDarkMode(darkModeMediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      updateDarkMode(e.matches);
    };

    darkModeMediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      darkModeMediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
};

export default useAdaptiveTheme;
