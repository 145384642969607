import React from "react";

const MathsIcon = () => {
  return (
    <svg
      // viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 1024 1024"
      strokeWidth="1.5"
      stroke="none"
      className="h-4 w-4"
    >
        <path
          d="M992 224a192 192 0 0 0-192-192H224a192 192 0 0 0-192 192v576a192 192 0 0 0 192 192h576a192 192 0 0 0 192-192zM376 832L304 764.16 232 832 160 764.16l72-67.84L160 628.48l72-67.84 72 67.84 72-67.84L448 628.48l-72 67.84L448 764.16zM448 352h-96v96H256v-96H160V256h96V160h96v96h96z m416 480h-288v-96h288z m0-160h-288v-96h288z m0-320h-288V256h288z"
        ></path>
    </svg>
  );
};

export default MathsIcon;
