import React from "react";
import { useAtBottom, useAtTop, useScrollToBottom, useScrollToTop, useAnimating } from "react-scroll-to-bottom";
import "./style.css";


const  TopBottomButton = () =>{
  const [atBottom] = useAtBottom();
  const [atTop] = useAtTop();
  const [animating] = useAnimating();
  const scrollToBottom = useScrollToBottom();
  const scrollToTop = useScrollToTop();
  if (animating) {

    return null;
  }

  const handleTop = () => {
    scrollToTop({ behavior: "auto"});
  };

  const handleBottom = () => {
    scrollToBottom({ behavior: "auto"});
  };

//  useEffect(() => {
//     function handleScroll() {
//       const contentEl = document.querySelector("#content-wrapper");
//       console.log(`contentEl: ${contentEl}`)
//       if (contentEl) {
//         setShowTop(contentEl.scrollTop > 0);
//         setShowBottom(
//           contentEl.scrollHeight - contentEl.scrollTop !==
//             contentEl.clientHeight
//         );
//       }
//     }
//     console.log(`--- TopBottomButton ---`);
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
 
  return (
    <div className="btn-top-down">
      {!atTop && (
      <button className="scroll-btn top" onClick={handleTop}>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 5L5.5 11.5H10V19H14V11.5H18.5L12 5Z" />
        </svg>
      </button>)}
      {!atBottom && (<button className="scroll-btn bottom" onClick={handleBottom}>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 18.5L19 12H14V5H10V12H5.5L12 18.5Z"/>
        </svg>
      </button>)}
    </div>
  );
}

export default TopBottomButton;
