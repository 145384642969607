import React from "react";

const MarkdownIcon = () => {
  return (
    <svg
      // viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 1024 1024"
      strokeWidth="1.5"
      stroke="none"
      className="h-4 w-4"
    >
      <path
        d="M795.559438 0H230.043058C102.242746 0 0.002496 102.24025 0.002496 230.040562v563.918876c0 127.800312 102.24025 230.040562 230.040562 230.040562h563.918876c127.800312 0 230.040562-102.24025 230.040562-230.040562V226.845554C1025.6 102.24025 923.35975 0 795.559438 0zM624.626521 749.229329h-102.24025V468.068643v-47.925117l-15.975039 22.365054v3.195008l-9.585023 12.780031-124.605304 188.505461-44.730109-70.290172-31.950078-51.120125-47.925117-79.875195-9.585024-15.975039v-3.195008l-15.975039-25.560062c0 38.340094 3.195008 63.900156 3.195008 83.070203v263.588143H121.412793V273.173167h159.75039l89.460218 130.99532 92.655226-130.99532h162.945398V750.826833H624.626521z m177.322933 0l-105.435257-140.580343h54.315132v-335.475819h102.24025v340.268331h54.315132l-105.435257 135.787831z"
      ></path>
    </svg>
  );
};

export default MarkdownIcon;
