import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { AssistantIcon, CopyIcon, MarkdownIcon, MathsIcon,
   MenuIcon, MessageFormat, PlusIcon, RawIcon,
   OpenaiModel,
   } from "../../constants";
import {
  GenConversationTitleRequest,
  GetConversationIdRequest,
  GetConversationRequest,
  SendWaitRequest,
  UpdateConversationFormatRequest,
} from "../../pb/api_pb";
import { setId, setTitle, setFormat } from "../../store/currentConversationBriefSlice";
import Footer from "../Footer";
import TopBottomButton from "../Widget/TopButtonButton";
import Markdown from "./markdown";
import "./style.css";
import Maths from "./maths";

const roleUser = "user";
const roleAssistant = "assistant";


const IconColors = {
  [OpenaiModel.GPT4]: "rgb(27, 26, 23)",
  [OpenaiModel.GPT4_Mobile]: "rgb(102, 0, 153)",
  [OpenaiModel.GPT3_5]: "rgb(200, 100, 150)",
  Uknown: "rgb(123, 45, 67)",
};

const RightSection = ({
  apiClient,
  changeConversation,
  setReload,
  show,
  setShow,
}) => {
  const [conversation, setConversation] = useState(undefined);
  const [talkable, setTalkable] = useState(true);
  const [continuable, setContinuable] = useState(false);
  const [request, setRequest] = useState(undefined);
  const [answering, setAnswering] = useState(false);
  const { id: conversationId, title: conversationTitle, format: defaultFormat } = useSelector(
    (state) => state.currentConversationBrief
  );
  const dispatch = useDispatch();

  const setDefuaultFormat = (format) => {
    dispatch(setFormat(format));
    const token = localStorage.getItem("token");
    const request = new UpdateConversationFormatRequest();
    request.setToken(token);
    request.setConversationId(conversationId);
    request.setFormat(format);
    apiClient.updateConversationFormat(request, {}, (err, response) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
        toast.error("设置默认格式失败");
        return;
      }
      toast.success("设置默认格式成功", {
        autoClose: 200,
        hideProgressBar: true,
        closeOnClick: true,
      });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!conversationId) {
        setConversation(undefined);
        return;
      }
      // console.log(`--- fetch conversation: ${conversationId} ---`);
      const request = new GetConversationRequest();
      const token = localStorage.getItem("token");
      request.setToken(token);
      request.setId(conversationId);

      apiClient.getConversation(request, {}, (err, response) => {
        if (err) {
          toast.error(err.message);
          console.log(err.code);
          return;
        }
        const conversation = response.toObject().conversation;
        // 由于 response 中的 mappingMap 是一个数组，所以需要转换成 Map
        conversation.mappings = conversation.mappingMap.reduce((acc, x) => {
          acc.set(x[0], x[1]);
          return acc;
        }, new Map());
        // console.log(`conversation: ${conversation}`);
        setConversation(conversation);
      });
    };
    fetchData();
  }, [apiClient, conversationId]);

  useEffect(() => {
    if (!conversationId || !conversation) {
      return;
    }
    if (!conversation.title) {
      console.log(`--- genConversationTitle ---`);
      const token = localStorage.getItem("token");
      const request = new GenConversationTitleRequest();
      request.setToken(token);
      request.setConversationId(conversationId);
      // TODO: accurate model slug
      request.setModelSlug("");
      request.setMessageId(
        conversation.mappings.get(conversation.currentNode).parent
      );
      apiClient.genConversationTitle(request, {}, (err, response) => {
        if (err) {
          console.log(err.code);
          console.log(err.message);
          return;
        }
        console.log(`genConversationTitle: ${response.toObject().title}`);
        dispatch(setTitle(response.toObject().title));
        setReload(true);
      });
    }
  }, [apiClient, conversation, conversationId, dispatch, setReload]);

  const renderConversation = () => {
    if (!conversation) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <div className="flex flex-col items-center gap-2">
            {/* <LightningChargeIcon className="w-16 h-16 text-gray-500" />
            <div className="text-gray-500">No conversation selected</div> */}
          </div>
        </div>
      );
    }
    const mappings = conversation.mappings;
    let currentNodeId = conversation.currentNode;
    // Define a recursive component for rendering nodes
    const messageList = [];
    while (currentNodeId) {
      const node = mappings.get(currentNodeId);
      if (!node) {
        break;
      }
      const message = node.message;
      const role = message?.author?.role;
      const parts = message?.content?.parts || message?.content?.partsList;
      // console.log(
      //   `message.modelSlug ${JSON.stringify(
      //     message?.metadata?.modelSlug
      //   )} gpt4=${gpt4}`
      // );
      if ([roleUser, roleAssistant].includes(role) && parts?.length > 0) {
        messageList.unshift({
          slug: message?.metadata?.modelSlug,
          role: role,
          content: parts[0],
        });
      }
      currentNodeId = node.parent;
    }
    // 找到相邻的两个 assistant 的 message，将第一个的 content 合并到第二个中
    const mergedMessageList = messageList.reduce((acc, currentMessage) => {
      const lastMessage = acc[acc.length - 1];
      if (lastMessage && lastMessage.role === "assistant" && currentMessage.role === "assistant") {
        lastMessage.content += currentMessage.content;
      } else {
        acc.push(currentMessage);
      }
      return acc;
    }, []);
    // console.log(`messageList: ${messageList.length}`);
    return mergedMessageList.map((message, index) => {
      return <ChatMessage key={index} message={message} defaultFormat={defaultFormat} setDefuaultFormat={setDefuaultFormat} />;
    });
  };

  useEffect(() => {
    if (answering || !conversation) {
      return
    }
    const latestMessage = conversation.mappings?.get(conversation.currentNode)?.message;
    if (latestMessage && latestMessage.metadata && latestMessage.metadata.finishDetails && (
      latestMessage.metadata.finishDetails.type === "max_tokens" || latestMessage.metadata.finishDetails.type === "interrupted")) {
      setContinuable(true);
      return;
    }
    if (latestMessage && latestMessage.metadata && latestMessage.metadata.finish_details && (
      latestMessage.metadata.finish_details.type === "max_tokens" || latestMessage.metadata.finish_details.type === "interrupted")) {
      setContinuable(true);
      return;
    }
  }, [conversation, answering]);

  useEffect(() => {
    if (!request) {
      return;
    }
    let parentId = request.getMessageId();
    if (continuable) {
      parentId = conversation.currentNode;
      console.log("setContinuable false");
      setContinuable(false);
    }
    const stream = apiClient.sendWait(request, {});
    setRequest(undefined);
    let currentConversationID;
    // Receive the stream
    stream.on("data", (response) => {
      const rawJson = response.getMessage();
      if (!rawJson) {
        return;
      }
      setAnswering(true);
      const o = JSON.parse(rawJson);
      currentConversationID = o.conversation_id;
      const message = o.message;
      setConversation({
        ...conversation,
        currentNode: message.id,
        mappings: conversation.mappings.set(message.id, {
          message: message,
          children: [],
          id: message.id,
          parent: parentId, // This is the parent of the message received from the server
        }),
      });
    });

    stream.on("end", () => {
      // console.log("Stream ended");
      setTalkable(true);
      setAnswering(false);
      if (!conversationId && currentConversationID) {
        const req = new GetConversationIdRequest();
        req.setToken(localStorage.getItem("token"));
        req.setId(currentConversationID);
        apiClient.getConversationId(req, {}, (err, response) => {
          if (err) {
            console.log(err.code);
            console.log(err.message);
            return;
          }
          const conversationId = response.getId();
          dispatch(setId(conversationId));
          changeConversation(conversationId);
        });
      }
    });

    stream.on("error", (err) => {
      console.log(err);
      toast.info("服务器遇到了一些问题，请稍后再试");
      setTalkable(true);
      setAnswering(false);
      if (!conversationId && currentConversationID) {
        changeConversation(currentConversationID);
      }
    });
  }, [
    apiClient,
    conversation,
    request,
    conversationId,
    changeConversation,
    dispatch,
    setReload,
  ]);

  const talk = (content, model, isContinue) => {
    if (!content && !isContinue) {
      return;
    }
    setTalkable(false);
    const request = new SendWaitRequest();
    const messageId = uuidv4();
    const parentId = conversation?.currentNode ?? uuidv4();
    request.setToken(localStorage.getItem("token"));
    request.setConversationId(conversationId);
    request.setMessage(content);
    request.setMessageId(messageId);
    request.setParentId(parentId);
    request.setSlug(model);
    if (content && !isContinue) {
      // console.log(`request: ${JSON.stringify(request.toObject())}`);
      const message = {
        message: {
          author: { role: roleUser, meta: {} },
          id: messageId,
          content: { partsList: [content] },
        },
        children: [],
        id: messageId,
        parent: parentId,
      };
      let _conversation = conversation;
      if (conversation) {
        _conversation = {
          ...conversation,
          currentNode: messageId,
          mappings: conversation.mappings.set(messageId, message),
        };
      } else {
        _conversation = {
          currentNode: messageId,
          mappings: new Map().set(messageId, message),
        };
      }
      setConversation(_conversation);
    }
    if (isContinue) {
      request.setMessage("");
      request.setMessageId("");
    }
    setRequest(request);
  };

  return (
    <div className="flex h-full flex-1 flex-col md:pl-[260px]">
      <div className="sticky top-0 z-10 flex items-center border-b border-white/20 bg-gray-800 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden">
        <button
          type="button"
          className={`-ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center outline-none justify-center rounded-md focus:ring-1 focus:ring-white ${!show && "!ring-0"
            } dark:hover:text-white text-gray-100`}
          onClick={() => setShow(!show)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon />
        </button>
        <h1 className="flex-1 text-center text-base font-normal">
          {conversationTitle ?? "New Chat"}
        </h1>
        <button type="button" className="px-3">
          <PlusIcon className="h-6 w-6" />
        </button>
      </div>
      <main className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
        <div className="flex-1 overflow-hidden">
          <ScrollToBottom
            initialScrollBehavior="auto"
            mode="bottom"
            className="h-full dark:bg-gray-800"
            followButtonClassName="scroll-to-bottom-button"
          >
            <div className="flex flex-col items-center text-sm dark:bg-gray-800">
              {renderConversation()}
              <div className="w-full h-32 md:h-48 flex-shrink-0"></div>
            </div>
            <TopBottomButton />
          </ScrollToBottom>
        </div>
        <Footer
          talk={talk}
          talkable={talkable}
          continuable={continuable}
          answering={answering}
          apiClient={apiClient}
        />
      </main>
    </div>
  );
};


// ChatMessage component
const ChatMessage = React.memo(({ message, defaultFormat, setDefuaultFormat }) => {
  const [format, setFormat] = useState(defaultFormat);
  return (
    <div
      className={
        "w-full border-b border-black/10 dark:border-gray-900/50 text-gray-800 dark:text-gray-100 group " +
        (message.role === roleAssistant
          ? "bg-gray-50 dark:bg-[#444654]"
          : "dark:bg-gray-800")
      }
    >
      <div className="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">
        <div className="w-[30px] flex flex-col relative items-end">
          <div
            className="relative h-[30px] w-[30px] p-1 rounded-sm text-white flex items-center justify-center"
            style={
              message.role === roleAssistant
                ? { backgroundColor: IconColors[message.slug] || IconColors.Uknown }
                : { backgroundColor: "rgb(255, 165, 0)" }
            }
          >
            {message.role === roleAssistant ? (
              <AssistantIcon className="h-6 w-5" />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
          <div className="flex flex-grow flex-col gap-3">
            <div className="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap">
              {/* {message.role === roleAssistant ? (
                <Markdown value={message.content} />
              ) : (
                message.content
              )} */}
              {/* {w ? message.content : <Markdown value={message.content} />} */}
              {format === MessageFormat.Raw ? message.content :
                format === MessageFormat.Maths ? <Maths text={message.content} /> :
                  format === MessageFormat.Markdown ? <Markdown value={message.content} /> : "Unknown"
              }
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray-400 flex self-end lg:self-center justify-center mt-2 gap-3 md:gap-4 lg:gap-1 lg:absolute lg:top-0 lg:translate-x-full lg:right-0 lg:mt-0 lg:pl-2 visible">
              <button
                className="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400"
                onClick={() => setFormat((format + 1) % 3)}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setDefuaultFormat(format);
                }}
              >
                {
                  format === MessageFormat.Raw ? <RawIcon className="h-6 w-5" /> :
                    format === MessageFormat.Maths ? <MathsIcon className="h-6 w-5" /> :
                      format === MessageFormat.Markdown ? <MarkdownIcon className="h-6 w-5" /> : "Unknown"
                }
              </button>
              <button
                className="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400"
                onClick={() => {
                  if (!navigator.clipboard) {
                    // show a toast to sugest user to change to https
                    toast.error(
                      "要使用拷贝功能，请切换至网址：https://chat.bohaisoft.com",
                      {
                        autoClose: 2000,
                        closeOnClick: true,
                      }
                    );
                    return;
                  }
                  navigator.clipboard.writeText(message.content);
                  // if copy is successful, show a toast for 1 second
                  toast.success("Copied to clipboard", {
                    autoClose: 200,
                    hideProgressBar: true,
                    closeOnClick: true,
                  });
                }}
              >
                <CopyIcon className="h-4 w-4"></CopyIcon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default RightSection;
