import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import { useState } from "react";
import LoginPage from "./page/Login";
import MainPage from "./MainPage";
import { ApiClient } from "./pb/api_grpc_web_pb";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "./store/authSlice";
import useAdaptiveTheme from "./hooks/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useAdaptiveTheme();
  // let apiClient = undefined;
  // if (window.location.protocol === "https:") {
  //   apiClient = new ApiClient("https://chat.bohaisoft.com:8443");
  // } else if (window.location.protocol === "http:") {
  //   apiClient = new ApiClient("http://chat.bohaisoft.com:9999");
  // }
  const apiClient = new ApiClient("https://chat.fyn.ink")

  const dispach = useDispatch();

  const [authenticated, setAuthenticated] = useState(
    useSelector((state) => state.auth.token)
  );
  const logout = () => {
    dispach(setToken(""));
    setAuthenticated(false);
  };
  // console.log("authenticated: ", authenticated);
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              authenticated ? <Navigate to="/main" /> : <Navigate to="/login" />
            }
          />
          <Route
            exact
            path="/login"
            element={
              authenticated ? (
                <Navigate to="/main" />
              ) : (
                <LoginPage
                  setAuthenticated={setAuthenticated}
                  apiClient={apiClient}
                />
              )
            }
          />
          <Route
            exact
            path="/main"
            element={
              authenticated ? (
                <MainPage logout={logout} apiClient={apiClient} />
              ) : (
                <LoginPage
                  setAuthenticated={setAuthenticated}
                  apiClient={apiClient}
                />
              )
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
