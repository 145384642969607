import { franc } from "franc-min";

const supportedLanguages = [
  "cmn",
  "spa",
  "eng",
  "rus",
  "arb",
  "ben",
  "hin",
  "por",
  "ind",
  "jpn",
  "fra",
  "deu",
];

class Speaker {
  constructor() {
    this.synth = window.speechSynthesis;
    this.voices = [];
    this.preferredVoices = this.loadPreferredVoices();
    this.voices = this.synth.getVoices();
    this.synth.onvoiceschanged = () => {
      this.voices = this.synth.getVoices();
    };
    // Set a preferred voice for British English
    this.setPreferVoice("en", "Google UK English Female");
    // this.setPreferVoice("en", "Google UK English Male", "GB");

    // Set a preferred voice for American English
    // this.setPreferVoice("en", "Google US English", "US");

    // Set a preferred voice for Simplified Chinese (China)
    this.setPreferVoice("zh", "Google 普通话（中国大陆）", "CN");

    // Set a preferred voice for Traditional Chinese (Hong Kong)
    // this.setPreferVoice("zh", "Google 粤語（香港）", "HK");

    // Set a preferred voice for Traditional Chinese (Taiwan)
    // this.setPreferVoice("zh", "Google 國語（臺灣）");
  }

  loadPreferredVoices() {
    const storedVoices = localStorage.getItem("preferredVoices");
    return storedVoices ? JSON.parse(storedVoices) : {};
  }

  savePreferredVoices() {
    localStorage.setItem(
      "preferredVoices",
      JSON.stringify(this.preferredVoices)
    );
  }

  getVoice(lang) {
    const preferredVoice = this.preferredVoices[lang];
    if (preferredVoice) {
      return this.voices.find((voice) => voice.name === preferredVoice);
    }
    this.voices.forEach((voice) => {
      console.log(`voice: ${voice.name}, lang: ${voice.lang}`);
    });
    return this.voices.find((voice) => voice.lang.startsWith(lang));
  }

  speakChunks(text, lang, voice) {
    if (text.length === 0) {
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text.shift());
    if (voice) {
      utterance.voice = voice;
    }

    utterance.lang = lang;
    utterance.onend = () => {
      this.speakChunks(text, lang, voice);
    };

    this.synth.speak(utterance);
  }

  speak(text, lang) {
    this.stop();

    if (!lang) {
      const detectedLang = franc(text, { only: supportedLanguages });
      lang = convertIso6393ToIso6391(detectedLang);
    }

    const voice = this.getVoice(lang);

    // Split the text into sentences
    const maxLength = 200; // You can adjust this value based on the platform and browser
    const sentenceRegex = /[^.!?\n。！？；]+[.!?\n。！？；]+/g;
    let sentences = text.match(sentenceRegex) || [text];

    // Split long sentences by comma
    sentences = sentences.reduce((result, sentence) => {
      if (sentence.length > maxLength) {
        const chunks = sentence.split(/[,，,]/).map((chunk) => chunk.trim());
        result.push(...chunks);
      } else {
        result.push(sentence);
      }
      return result;
    }, []);

    this.speakChunks(sentences, lang, voice);
  }

  stop() {
    this.synth.cancel();
  }

  setPreferVoice(lang, voiceName) {
    this.preferredVoices[lang] = voiceName;
    this.savePreferredVoices();
  }
}
function convertIso6393ToIso6391(iso6393Code) {
  const mapping = {
    cmn: "zh",
    spa: "es",
    eng: "en",
    rus: "ru",
    arb: "ar",
    ben: "bn",
    hin: "hi",
    por: "pt",
    ind: "id",
    jpn: "ja",
    fra: "fr",
    deu: "de",
  };
  return mapping[iso6393Code] || iso6393Code;
}

export default Speaker;

/*
cmn	Mandarin Chinese	885M
spa	Spanish	332M
eng	English	322M
rus	Russian	288M
arb	Standard Arabic	280M
ben	Bengali	196M
hin	Hindi	182M
por	Portuguese	182M
ind	Indonesian	140M
jpn	Japanese	125M
fra	French	124M
deu	German	121M
*/
