import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import "./markdown.css";

const Markdown = ({ value }) => {
  const Pre = ({ children }) => (
    <pre className="blog-pre">
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );

  return (
    <ReactMarkdown
      className={"markdown prose w-full break-words dark:prose-invert light"}
      components={{
        pre: Pre,
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, "")}
              style={atomOneDark}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
      children={value}
      remarkPlugins={[remarkGfm]}
    />
  );
};

function CodeCopyBtn({ children }) {
  const [copyOk, setCopyOk] = useState(false);
  const [language, setLanguage] = useState("");

  React.useEffect(() => {
    const className = children.className;
    const match = /language-(\w+)/.exec(className || "");
    if (match) {
      setLanguage(match[1]);
    }
  }, [children]);

  const handleClick = (e) => {
    const copyText = children[0]?.props?.children[0] ?? "";
  
    const copyToClipboard = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
  
      try {
        const successful = document.execCommand("copy");
        if (successful) {
          setCopyOk(true);
          setTimeout(() => {
            setCopyOk(false);
          }, 1000);
        } else {
          throw new Error("Failed to copy text");
        }
      } catch (err) {
        console.error("Error copying text: ", err);
      }
  
      document.body.removeChild(textArea);
    };
  
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyText).then(() => {
        setCopyOk(true);
        setTimeout(() => {
          setCopyOk(false);
        }, 1000);
      }).catch((err) => {
        console.error("Failed to copy text: ", err);
        // Fallback to the textarea method if Clipboard API failed
        copyToClipboard(copyText);
      });
    } else {
      // Fallback for environments where Clipboard API is not supported
      copyToClipboard(copyText);
    }
  };
  

  return (
    <div className="code-bar">
      <i>{language}</i>
      <i
        className={"code-copy-btn"}
        onClick={handleClick}
        style={{ color: "rgb(217,217,227)" }}
      >
        {copyOk ? "Copied!" : "Copy code"}
      </i>
    </div>
  );
}

export default Markdown;
