import React from "react";

const RawIcon = () => {
  return (
    <svg
      // viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 1024 1024"
      strokeWidth="1.5"
      stroke="none"
      className="h-4 w-4"
    >
      {/* <path
        d="M332.799002 686.081014m-332.799002 0a332.799002 332.799002 0 1 0 665.598003 0 332.799002 332.799002 0 1 0-665.598003 0Z"
      ></path> */}
      <path
        d="M883.19735 1024h-639.99808A141.055577 141.055577 0 0 1 102.399693 883.200422v-742.397772A141.055577 141.055577 0 0 1 243.19927 0.003072h516.350451a89.087733 89.087733 0 0 1 63.231811 25.599923l189.695431 189.695431A38.399885 38.399885 0 0 1 1023.996928 243.202342v639.99808a141.055577 141.055577 0 0 1-140.799578 140.799578zM243.19927 76.802842A63.999808 63.999808 0 0 0 179.199462 140.80265v742.397772A63.999808 63.999808 0 0 0 243.19927 947.20023h639.99808a63.999808 63.999808 0 0 0 63.999808-63.999808V259.074295l-179.199462-179.199463a12.799962 12.799962 0 0 0-8.447975-3.07199z"
      ></path>
      <path
        d="M380.414859 624.897197l-51.199847-91.135726h-36.60789v91.135726H255.999232v-225.791322h73.983778c46.59186 0 81.663755 16.63995 81.663755 65.279804a62.975811 62.975811 0 0 1-47.359858 64.767806l55.295834 95.743712z m-87.807737-119.80764h34.047898c32.767902 0 51.199846-13.567959 51.199846-40.703878s-17.919946-36.863889-51.199846-36.86389h-34.047898zM560.638318 560.64139h-78.591764l-19.455942 64.511807h-36.095891l75.007774-226.047322h41.215877l75.007775 226.047322h-37.887887z m-8.703974-27.903916l-8.959973-30.463909c-7.679977-25.599923-14.335957-49.663851-21.503935-75.263774-6.399981 25.599923-13.567959 51.199846-20.991937 75.263774l-9.215973 30.463909zM624.382127 399.105875h36.863889l21.247937 117.503647 11.775964 72.191784c4.863985-24.319927 10.239969-48.383855 15.359954-72.447783l29.183913-117.503648h31.231906l29.439912 117.503648c5.119985 23.551929 10.239969 47.871856 15.359953 72.447783h1.535996c3.583989-25.599923 7.679977-48.895853 11.263966-72.447783l21.503936-117.503648h34.047897l-44.543866 226.047322h-42.239873l-30.463909-125.695623c-4.095988-17.919946-7.423978-34.815896-10.495968-51.199846h-1.535996c-3.071991 17.151949-6.65598 34.047898-10.495968 51.199846l-29.695911 125.695623H670.717988z"
      ></path>
    </svg>
  );
};

export default RawIcon;
