import { configureStore } from "@reduxjs/toolkit";
import currentConversationBriefReducer from "./currentConversationBriefSlice";
import authReducer from './authSlice';
import { localStorageMiddleware } from "./middleware";

export default configureStore({
  reducer: {
    currentConversationBrief: currentConversationBriefReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});
