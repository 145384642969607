import React, { useRef, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  EditIcon,
  DeleteIcon,
  LogOutIcon,
  PlusIcon,
  CancelIcon,
  MessageFormat,
} from "../../constants";
import {
  GetConversationsRequest,
  DeleteConversationRequest,
  UpdateConversationTitleRequest,
} from "../../pb/api_pb";
import OkButton from "../../constants/svg/okIcon";
import { Tooltip } from "react-tooltip";
import { wrapText } from "./tooltip";
import { setTitle, updateCurrentConversation } from "../../store/currentConversationBriefSlice";

const titleNewChat = "New Chat";

const LeftSection = ({
  show = false,
  logout,
  apiClient,
  changeConversation,
  reload,
  setReload,
}) => {
  const currentConversationId = useSelector(
    (state) => state.currentConversationBrief.id
  );
  // const currentConversationTitle = useSelector(
  //   (state) => state.currentConversationBrief.title
  // );
  // console.log (`================leftSection reload: ${currentConversationId}, currentConversationTitle: ${currentConversationTitle}`);
  const dispatch = useDispatch();
  const [conversations, setConversations] = useState([]);
  // console.log("currentConversationId: ", currentConversationId);
  const changeDocumentTitle = useCallback(
    (conversationId) => {
      if (!conversationId) {
        document.title = titleNewChat;
      } else {
        const conversation = conversations.find(
          (conversation) => conversation.getId() === conversationId
        );
        if (conversation) {
          document.title = conversation.getTitle();
        }
      }
    },
    [conversations]
  );

  const handleDeleteConversation = (conversationId) => {
    const token = localStorage.getItem("token");
    const req = new DeleteConversationRequest();
    req.setToken(token);
    req.setConversationId(conversationId);
    apiClient.deleteConversation(req, {}, (err, response) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
        return;
      }
      // console.log(`delete conversation ${conversationId} success`);
      dispatch(updateCurrentConversation({ id: "", title: "", format: MessageFormat.Markdown }));
      setReload(true);
    });
  };
  const handleUpdateConversationTitle = (conversationId, title) => {
    const token = localStorage.getItem("token");
    const req = new UpdateConversationTitleRequest();
    req.setToken(token);
    req.setConversationId(conversationId);
    req.setTitle(title);
    apiClient.updateConversationTitle(req, {}, (err, response) => {
      if (err) {
        console.log(err.code);
        console.log(err.message);
        return;
      }
      dispatch(setTitle(title));
    });
  };

  useEffect(() => {
    changeDocumentTitle(currentConversationId);
  }, [changeDocumentTitle, currentConversationId]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log("=====reload: ", reload)
      if (!reload) {
        return;
      } else {
        setReload(false);
      }
      const request = new GetConversationsRequest();
      request.setToken(localStorage.getItem("token"));
      request.setOffset(0);
      request.setLimit(100);

      apiClient.getConversations(request, {}, (err, response) => {
        if (err) {
          console.log(err.code);
          console.log(err.message);
          return;
        }
        const current = response.toObject().conversationList.find((conversation) => conversation.id === currentConversationId);
        if (current) {
          dispatch(updateCurrentConversation({ id: current.id, title: current.title, format: current.format }));
        }
        const conversationsList = response.getConversationList();
        setConversations(conversationsList);
      });
    };
    fetchData();
  }, [apiClient, conversations, currentConversationId, dispatch, reload, setReload]);
  return (
    <div
      className={`${show && " flex flex-col"} ${
        !show && "hidden"
      } bg-black md:fixed md:inset-y-0 md:flex md:w-[260px] md:flex-col`}
    >
      <Tooltip
        anchorSelect=".conversation-title-anchor"
        delayShow={500} // delay in ms
        style={{
          maxWidth: "240px",
          backgroundColor: "#ffffff",
          color: "#000000",
          fontSize: "14px",
          padding: "8px",
          borderRadius: "4px",
          opacity: "1",
          zIndex: "9999",
        }}
        place="bottom"
        multiline={true}
      />
      <div className="flex h-full min-h-0 flex-col ">
        <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
          <nav className="flex h-full flex-1 flex-col space-y-1 p-2">
            <button
              className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm mb-2 flex-shrink-0 border border-white/20"
              onClick={() => {
                changeDocumentTitle("");
                changeConversation("");
                dispatch(updateCurrentConversation({ id: "", title: "", format: MessageFormat.Markdown }));
              }}
            >
              <PlusIcon />
              {titleNewChat}
            </button>
            <div className="flex-col flex-1 overflow-y-auto border-b border-white/20 -mr-2">
              <div className="flex flex-col gap-2 text-gray-100 text-sm">
                {conversations.map((conversation, index) => (
                  <ConversationItem
                    // key={index} wierd bugs
                    key={conversation.getId()}
                    title={conversation.getTitle()}
                    selected={currentConversationId === conversation.getId()}
                    onSelect={() => {
                      const conversationId = conversation.getId();
                      changeDocumentTitle(conversationId);
                      changeConversation(conversationId);
                      dispatch(updateCurrentConversation({ id: conversationId, title: conversation.getTitle(), format: conversation.getFormat() }));
                      console.log(`select: ${conversationId} ${conversation.getTitle()} ${conversation.getFormat()}`);
                    }}
                    onDelete={() => {
                      console.log("delete: ", conversation.getId());
                      handleDeleteConversation(conversation.getId());
                    }}
                    onReTitle={(newTitle) => {
                      console.log("retitle: ", conversation.getId(), newTitle);
                      handleUpdateConversationTitle(
                        conversation.getId(),
                        newTitle
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            {[
              // {
              //   icon: (
              //     <SunIcon
              //       className="h-4 w-4 text-white font-bold"
              //       strokeWidth="2"
              //     />
              //   ),
              //   text: "Light mode",
              // },
              // {
              //   icon: <DiscordIcon />,
              //   text: "OpenAI Discord",
              //   onClick: () => {},
              // },
              // { icon: <ExternalLinkIcon />, text: "Updates & FAQ" },
              { icon: <LogOutIcon />, text: "Log out", onClick: logout },
            ].map((item, index) => (
              <button
                className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm"
                key={index}
                onClick={item.onClick}
              >
                {item.icon}
                {item.text}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default LeftSection;

function ConversationItem({ title, selected, onSelect, onDelete, onReTitle }) {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const ref = useRef(null);

  const [tipHtml, setTipHtml] = useState(newTitle);

  useEffect(() => {
    if (ref.current) {
      const computedStyle = window.getComputedStyle(ref.current);
      const fontFamily = computedStyle.getPropertyValue("font-family");
      const fontSize = computedStyle.getPropertyValue("font-size");
      const fontWeight = computedStyle.getPropertyValue("font-weight");
      const fontStyle = computedStyle.getPropertyValue("font-style");

      const font = `${fontStyle} ${fontWeight} ${fontSize} ${fontFamily}`;
      if (!font) {
        setTipHtml(newTitle);
        return;
      }
      const wrappedTextLines = wrapText(newTitle, 220, font);
      setTipHtml(`<div>${wrappedTextLines.join("<br/>")}</div>`);
    }
  }, [ref, newTitle]);

  const returnNormal = () => {
    setEditing(false);
    setDeleting(false);
  };

  const handleOK = () => {
    if (editing) {
      onReTitle(newTitle);
    } else if (deleting) {
      onDelete();
    }
    returnNormal();
  };

  return (
    <div
      className={
        "flex py-3 px-3 items-center gap-3 relative rounded-md cursor-pointer break-all pr-14 hover:bg-gray-800 group" +
        (selected ? " bg-gray-500/10" : "")
      }
      onClick={(e) => {
        if (selected) {
          return;
        }
        onSelect(e);
      }}
    >
      <div
        className="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative conversation-title-anchor"
        ref={ref}
        // data-tooltip-content={newTitle}
        data-tooltip-html={tipHtml}
      >
        {editing ? (
          <input
            type="text"
            autoFocus={true}
            className="text-sm border-none bg-transparent p-0 m-0 w-full mr-0"
            defaultValue={title}
            value={newTitle}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleOK();
              }
            }}
            onChange={(e) => {
              setNewTitle(e.target.value);
            }}
          ></input>
        ) : (
          newTitle
        )}
      </div>
      {selected &&
        (!(editing || deleting) ? (
          <div className="absolute flex right-1 z-10 text-gray-300 visible">
            <button
              className="p-1 hover:text-white"
              onClick={() => {
                setEditing(true);
              }}
            >
              <EditIcon />
            </button>
            <button
              className="p-1 hover:text-white"
              onClick={() => {
                setDeleting(true);
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        ) : (
          <div className="absolute flex right-1 z-10 text-gray-300 visible">
            <button
              className="p-1 hover:text-white"
              onClick={() => {
                handleOK();
              }}
            >
              <OkButton />
            </button>
            <button
              className="p-1 hover:text-white"
              onClick={() => {
                returnNormal();
              }}
            >
              <CancelIcon />
            </button>
          </div>
        ))}
    </div>
  );
}
