import { createSlice } from '@reduxjs/toolkit'
import DB from '../db'
import { MessageFormat } from '../constants'

export const currentConversationBriefSlice = createSlice({
  name: 'currentConversationBrief',
  initialState: {
    id: DB.getCurrentConversationId(),
    title: "",
    format: MessageFormat.Markdown,
  },
  reducers: {
    setId: (state, action) => {
      // console.log("currentConversationBriefSlice: setId: ", action.payload)
      state.id = action.payload
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setFormat: (state, action) => {
      state.format = action.payload
    },
    updateCurrentConversation: (state, action) => {
      const { id, title, format } = action.payload
      state.id = id
      state.title = title
      state.format = format
    },
  }
})

// Action creators are generated for each case reducer function
export const { setId, setTitle, setFormat, updateCurrentConversation } = currentConversationBriefSlice.actions

export default currentConversationBriefSlice.reducer
