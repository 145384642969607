import React, { useCallback, useEffect, useState } from "react";
import VoiceIcon from "../../../constants/svg/voiceIcon";
import "./style.css";

function getUserAgent() {
  return navigator && navigator.userAgent ? navigator.userAgent : "";
}

function userAgentContains(a) {
  return -1 !== getUserAgent().indexOf(a);
}
function IsCSS1Compat(a) {
  return "CSS1Compat" === a.compatMode;
}

const TextSelectionButton = ({ speaker, onContextMenu }) => {
  const [buttonVisible, setButtonVisible] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ left: 0, top: 0 });
  const [text, setText] = useState("");
  const isWebkitNotEdge =
    -1 !== getUserAgent().toLowerCase().indexOf("webkit") &&
    !userAgentContains("Edge");

  const getScrollingElement = useCallback(
    (doc) => {
      return doc.scrollingElement
        ? doc.scrollingElement
        : !isWebkitNotEdge && IsCSS1Compat(doc)
        ? doc.documentElement
        : doc.body || doc.documentElement;
    },
    [isWebkitNotEdge]
  );

  const handleTextSelection = useCallback(
    (event) => {
      const selection = window.getSelection();
      const rect = selection.getRangeAt(0).getBoundingClientRect();
      if (rect.top === 0 && rect.left === 0) {
        return;
      }

      const isTextSelected = selection.toString().length > 0;

      if (isTextSelected) {
        const left =
          event.clientX + getScrollingElement(document).scrollLeft - 13;
        //   const top = event.clientY - selection.top > selection.height / 2 ? (selection.bottom + 1) : (selection.top - 1 - 27) + getScrollingElement(document).scrollTop + "px";
        let x = event.clientY;
        console.log(
          `x: ${x}, selection.top: ${selection.top}, selection.height: ${selection.height}`
        );
        x - rect.top > rect.height / 2
          ? (x = rect.bottom + 1)
          : (x = rect.top - 1 - 27);
        const top = x + getScrollingElement(document).scrollTop;
        // const top = clientY + scrollTop - 20;
        // console.log("left: ", left, "top: ", top);
        setButtonPosition({ left: left + 30, top: top });
        const _text = decodeURIComponent(encodeURIComponent(selection.toString().trim()));
        if (_text !== "") {
          setText(_text);
          setButtonVisible(true);
        }
      } else {
        setButtonVisible(false);
      }
    },
    [setButtonVisible, setButtonPosition, getScrollingElement]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleTextSelection);
    return () => {
      document.removeEventListener("mouseup", handleTextSelection);
    };
  }, [handleTextSelection]);

  const handleContextMenu = (event) => {
    if (onContextMenu) {
      console.log("handleContextMenu");
      event.preventDefault();
      onContextMenu(event);
    }
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    speaker.speak(text);
  }

  if (!buttonVisible) {
    return null;
  }

  return (
    <div>
      <button
        className="text-selection-button"
        style={{
          position: "absolute",
          left: buttonPosition.left,
          top: buttonPosition.top,
          zIndex: 3147483647,
        }}
        onContextMenu={handleContextMenu}
        onClick={handleButtonClick}
      >
        <VoiceIcon />
      </button>
    </div>
  );
};

export default TextSelectionButton;
