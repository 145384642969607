import React, { useEffect, useRef, useState } from "react";
import { OpenaiModel, PlaneIcon } from "../../constants";
import "./style.css";
import QueueTip from "../Widget/QueueTip";
import { useSelector } from "react-redux";

const Footer = ({ talk, talkable, continuable, answering, apiClient }) => {
  const [textareaValue, setTextareaValue] = useState("");
  const inputArea = useRef(null);
  const plus = useSelector((state) => state.auth.plus);
  const [model, setModel] = useState(plus?OpenaiModel.GPT4:OpenaiModel.GPT3_5);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      setTextareaValue(event.detail.value);
    };

    window.addEventListener("updateTextarea", handleCustomEvent);

    return () => {
      window.removeEventListener("updateTextarea", handleCustomEvent);
    };
  }, []);

  const handleTextareaChange = (event) => {
    // inputArea.style.height = 'auto';
    const lines = inputArea.current.value.split("\n");
    setTextareaValue(event.target.value);
    if (lines.length > 5) {
      return;
    }
    inputArea.current.style.height = `${Math.min(
      inputArea.current.scrollHeight
    )}px`;
  };

  const handleSubmit = (event) => {
    inputArea.current.style.height = "auto";
    event.preventDefault();
    // reset textarea value after submission
    setTextareaValue("");
    talk(textareaValue, model??OpenaiModel.GPT3_5, false);
    reset();
  };

  const handleContinuation = (event) => {
    inputArea.current.style.height = "auto";
    event.preventDefault();
    talk("", model??OpenaiModel.GPT3_5, true); 
    reset();
  };

  const handleInputAreaKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const reset = () => {
      // setModel(plus ? model : OpenaiModel.GPT3_5);
  }

  return (
    <div className="absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-white dark:bg-gray-800 md:!bg-transparent dark:md:bg-vert-dark-gradient">
      <div className="flex flex-col md:flex-row md:items-center md:gap-4 md:px-4 md:py-2 md:dark:bg-gray-800 dark:bg-gray-800 md:dark:bg-gray-800 bg-white dark:bg-gray-800 md:bg-transparent items-center justify-center">
        <QueueTip
          apiClient={apiClient}
          talkable={talkable}
          answering={answering}
        />
        {continuable && !textareaValue && (
          <button
            type="button"
            onClick={handleContinuation}
            className="bg-opacity-50 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Continue
          </button>
        )}
      </div>
      <form
        onSubmit={handleSubmit}
        className="stretch mx-2 flex flex-row gap-3 pt-2 last:mb-2 md:last:mb-6 lg:mx-auto lg:max-w-3xl lg:pt-6"
      >
        <div className="relative flex h-full flex-1">
          <select 
          value={model} 
          onChange={handleModelChange}
          className="mr-2 border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]"
          >
            <option value={OpenaiModel.GPT3_5}>3.5</option>
            <option value={OpenaiModel.GPT4_Mobile}>4m</option>
            {plus && <option value={OpenaiModel.GPT4}>4p</option>}
          </select>
          {/* Input */}
          <div className="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
            <textarea
              ref={inputArea}
              tabIndex="0"
              data-id="root"
              rows="1"
              className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent outline-none overflow-y-hidden h-[23px]"
              value={textareaValue}
              onChange={handleTextareaChange}
              onKeyDown={handleInputAreaKeyDown}
            ></textarea>
            {talkable ? (
              <button
                type="submit"
                className="absolute p-1 rounded-md text-gray-400 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-black"
              >
                <PlaneIcon />
              </button>
            ) : (
              <button
                className="absolute p-1 rounded-md text-gray-500 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-gray-100 dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent disabled:bottom-0.5 md:disabled:bottom-1  animated-dots"
                disabled=""
              >
                <div className="text-2xl">
                  <span className="dot active">·</span>
                  <span className="dot active">·</span>
                  <span className="dot active">·</span>
                </div>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Footer;
