const keyCurrentConversationId = "currentConversationId";
const keyToken = "token";
class DB {
  static getCurrentConversationId() {
    return localStorage.getItem(keyCurrentConversationId);
  }
  static setCurrentConversationId(conversationId) {
    localStorage.setItem(keyCurrentConversationId, conversationId);
  }

  static getToken() {
    return localStorage.getItem(keyToken);
  }
  static setToken(token) {
    return localStorage.setItem(keyToken, token);
  }

}

export default DB;
