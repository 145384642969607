import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { LeftSection, RightSection } from "./Components";
import TextSelectionButton from "./Components/Widget/TextSelectionButton";
import Speaker from "./Components/Widget/TextSelectionButton/speaker";

function MainPage({ logout, apiClient }) {
  // Toggle
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(true);
  const changeConversation = (conversationId) => {
    setShow(false);
  };
  const htmlElement = document.createElement("div", {
    style: "z-index: 2147483647",
  });
  const speaker = new Speaker();

  useEffect(() => {
    document.documentElement.appendChild(htmlElement);

    return () => {
      document.documentElement.removeChild(htmlElement);
    };
  }, [htmlElement]);
  return (
    <>
      {ReactDOM.createPortal(
        <TextSelectionButton speaker={speaker} />,
        htmlElement
      )}
      <div className="overflow-hidden w-full h-full relative">
        {/* Left Section */}
        <LeftSection
          show={show}
          logout={logout}
          apiClient={apiClient}
          changeConversation={changeConversation}
          reload={reload}
          setReload={setReload}
        />

        {/* Right Section */}
        <RightSection
          apiClient={apiClient}
          changeConversation={changeConversation}
          setReload={setReload}
          show={show}
          setShow={setShow}
        />
      </div>
      <div className="absolute top-0 left-0 right-0 z-[2]"></div>
    </>
  );
}

export default MainPage;
