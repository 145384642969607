import { useEffect, useState, useRef } from "react"
import { GetQueueNumberRequest } from "../../../pb/api_pb"

const QueueTip = ({ apiClient, talkable, answering }) => {
  const [queueNumber, setQueueNumber] = useState(0);
  const timerRef = useRef(null);

  const showable = () => {
    return !talkable && !answering && queueNumber >= 1;
  };

  useEffect(() => {
    if (talkable) {
      return;
    }
    timerRef.current= setInterval(() => {
      const request = new GetQueueNumberRequest();
      request.setToken(localStorage.getItem("token"));

      apiClient.getQueueNumber(request, {}, (err, response) => {
        if (err) {
          console.log(err.code);
          console.log(err.message);
          return;
        }
        const number = response.getNumber();
        // console.log("queue number: ", number);
        setQueueNumber(response.getNumber());
        if  (number < 1) {
          clearInterval(timerRef.current);
        }
      });

    }, 1000);
    return () => {
      clearInterval(timerRef.current);
    }
  }, [talkable, apiClient]);
  return (
    <div className="queue-tip">
      {showable() && (
        <div className="queue-tip-content">
          <div className="queue-tip-text">
            <span>Queue: </span>
            <span className="queue-tip-number">{queueNumber}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default QueueTip;
