import React from "react";
import katex from "katex";

import "./maths.css";

const renderMaths = (text) => {
  const result = [];
  const regex = /\$\$[\s\S]+?\$\$|\$[\s\S]+?\$/g;
  let lastIndex = 0;

  let match;
  while ((match = regex.exec(text)) !== null) {
    const startIndex = match.index;
    const endIndex = regex.lastIndex;

    result.push(text.slice(lastIndex, startIndex));
    const latexContent = match[0].slice(
      match[0].startsWith("$$") ? 2 : 1,
      -(match[0].endsWith("$$") ? 2 : 1)
    );

    try {
      const renderedMath = katex.renderToString(latexContent);
      result.push(
        <span
          key={startIndex}
          className={
            match[0].startsWith("$$")
              ? "custom-block-math"
              : "custom-inline-math"
          }
          dangerouslySetInnerHTML={{ __html: renderedMath }}
        />
      );
    } catch (error) {
      result.push(match[0]);
    }

    lastIndex = endIndex;
  }

  result.push(text.slice(lastIndex));
  return result;
};

const Maths = ({ text }) => {
  return <div>{renderMaths(text)}</div>;
};

export default Maths;
